<template>
  <b-modal
    id="emp-attendance-manage-modal"
    footer-bg-variant="light"
    :title="title"
    size="lg"
    lazy
  >
    <div class="d-flex flex-column flex-lg-row border-bottom pb-5">
      <CommonEmployeeSummary :item="employee"></CommonEmployeeSummary>
      <EmployeeAttendanceManageDate
        type="full"
        :date="date"
      ></EmployeeAttendanceManageDate>
    </div>

    <EmployeeAttendanceManageForm
      class="mx-2 my-5"
    ></EmployeeAttendanceManageForm>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="light-primary"
        class="btn-hover-danger"
        @click="cancel()"
        >Close
      </b-button>
      <b-button
        variant="primary"
        v-on:click="updateAttStatus"
        v-bind:class="{
          'spinner spinner-light spinner-right': held,
        }"
        >Submit</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import EmployeeAttendanceManageForm from "@/modules/company/components/attendance/manage/EmployeeAttendanceManageForm";
import EmployeeAttendanceManageDate from "@/modules/company/components/shared/EmployeeAttendanceManageDate";

export default {
  name: "EmployeeAttendanceManageModal",
  components: {
    EmployeeAttendanceManageDate,
    EmployeeAttendanceManageForm,
    CommonEmployeeSummary,
  },
  data: function () {
    return {
      isBusy: false,
    };
  },
  methods: {
    updateAttStatus() {
      console.log("Emitting emp att status event..");
      this.$store.dispatch("engageUpdateEmployeeAttManageForm");
    },
  },
  computed: {
    ...mapGetters({
      employee: "getAssignedDailyEmployeeAttendance",
      held: "getHeldEmpEditAttStatus",
    }),
    title() {
      return `Manage ${this.employee.name} Attendance`;
    },
    date() {
      return new Date(this.$store.getters.getDateEmployeeAttendance);
    },
  },
};
</script>

<style scoped></style>
