<template>
  <div>
    <b-table
      ref="employee-attendance-table"
      responsive="sm"
      table-class="table-head-custom table-vertical-center mt-3"
      hover
      :items="getDailyEmployeeAttendance"
      :fields="fields"
      :busy="isBusy"
    >
      <!-- begin:header template    -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end:header template    -->

      <!-- begin: cell templates      -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">
          {{ data.index + 1 }}
        </span>
      </template>

      <template v-slot:cell(time_in)="data">
        <span class="font-weight-bolder" v-if="data.item.attendance">
          {{ data.item.attendance.time_in }}
        </span>
      </template>

      <template v-slot:cell(time_out)="data">
        <span class="font-weight-bolder" v-if="data.item.attendance">
          {{ data.item.attendance.time_out }}
        </span>
      </template>

      <template v-slot:cell(employee)="data">
        <CommonEmployeeSummary :item="data.item"></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(temperature)="data">
        <span
          class="font-weight-bolder"
          v-bind:class="{
            'text-success': data.item.attendance.temperature.is_safe,
            'text-danger': !data.item.attendance.temperature.is_safe,
          }"
          v-if="data.item.attendance && data.item.attendance.temperature"
        >
          {{ data.item.attendance.temperature.temp + "\xB0C" }}
        </span>
      </template>

      <!-- begin:: cell templates      -->
      <template v-slot:cell(status)="data">
        <span class="font-weight-bolder">
          {{ data.item.att_status !== null ? data.item.att_status.name : "" }}
        </span>
      </template>
      <!-- end:: cell templates      -->

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-right">
          <!-- begin: Device Users Modal Button         -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showClockRecord(data)"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
            </span>
          </button>
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showManageModal(data)"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
          </button>
        </div>
      </template>
      <!-- end: cell templates      -->

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>
    </b-table>

    <EmployeePageClockRecordModal
      v-bind:attendance-id="attendanceId"
    ></EmployeePageClockRecordModal>

    <div
      class="d-flex justify-content-end"
      v-if="getDailyEmployeeAttendance.length"
    >
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="refresh"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
      ></b-pagination>
    </div>

    <EmployeeAttendanceManageModal></EmployeeAttendanceManageModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { alert } from "sweetalert2";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import EmployeePageClockRecordModal from "@/modules/company/components/employees/EmployeePageClockRecordModal";
import EmployeeAttendanceManageModal from "@/modules/company/components/attendance/manage/EmployeeAttendanceManageModal";
import i18n from "@/core/plugins/vue-i18n";
import dayjs from "dayjs";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";

export default {
  name: "EmployeeAttendancePageTable",
  props: ["reload"],
  components: {
    CommonEmployeeSummary,
    EmployeeAttendanceManageModal,
    EmployeePageClockRecordModal,
    AppLoader,
  },
  data() {
    return {
      attendanceId: null,
      fields: [
        { key: "index", label: "#" },
        { key: "employee", label: i18n.t("EMPLOYEE.HEADER") },
        { key: "status", label: "Status" },
        { key: "time_in", label: i18n.t("ATTENDANCE.IN") },
        { key: "time_out", label: i18n.t("ATTENDANCE.OUT") },
        { key: "temperature", label: i18n.t("ATTENDANCE.TEMPERATURE") },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.isBusy = true;
      return this.$store
        .dispatch("getEmployeeAttendanceList")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        })
        .finally(() => (this.isBusy = false));
    },

    showClockRecord(data) {
      console.log(data);
      this.attendanceId = data.item.attendance ? data.item.attendance.id : null;
      this.$bvModal.show("employee-page-clock-record-modal");
    },

    /**
     * @param data
     */
    showManageModal(data) {
      console.log(data);
      this.$store.commit("setDailyEmployeeAttendanceFormState", false);
      this.$store.dispatch("assignDailyEmployeeAttendance", {
        data: data.item,
        date: dayjs(new Date()).format("YYYY-MM-DD"),
      });
      this.$bvModal.show("emp-attendance-manage-modal");
    },

    refresh(page) {
      this.$store.commit("setDailyEmployeeAttendancePaginationPage", page);
      this.fetch();
    },
  },
  watch: {
    reload() {
      console.log("Reload Table!");
      this.fetch();
    },
    close(newClose) {
      console.log("New Close Event");
      if (newClose === true) {
        this.$bvModal.hide("emp-attendance-manage-modal");
        this.fetch();
      }
    },
  },

  computed: {
    ...mapGetters(["getDailyEmployeeAttendance"]),
    ...mapGetters({
      close: "getDailyEmployeeAttendanceFormState",
      pagination: "getDailyEmployeeAttendancePagination",
    }),
  },
};
</script>

<style scoped></style>
