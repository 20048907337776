<template>
  <b-modal
    id="employee-page-clock-record-modal"
    title-class="h4 font-weight-bold"
    :title="$t('CLOCKPAGE.HEADER')"
    size="lg"
    scrollable
    lazy
    footer-bg-variant="light"
  >
    <div class="d-flex justify-content-center">
      <EmployeePageClockRecord :attendance-id="attendanceId">
      </EmployeePageClockRecord>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button variant="light" class="btn-hover-danger" @click="cancel">
        {{ $t("BUTTON.CANCEL") }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import EmployeePageClockRecord from "@/modules/company/components/employees/EmployeePageClockRecord";
export default {
  name: "EmployeePageClockRecordModal",
  components: { EmployeePageClockRecord },
  props: {
    attendanceId: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style scoped></style>
