<template>
  <div class="">
    <!-- begin: Table Controls    -->
    <div class="d-flex my-3 mx-3 p-2">
      <b-form-group label="Year" class="mr-3">
        <b-form-select
          v-model="yearSelection"
          :options="years"
          class="form-control-solid"
        >
        </b-form-select>
      </b-form-group>
      <b-form-group label="Month">
        <b-form-select
          v-model="monthSelection"
          :options="months"
          class="form-control-solid"
        >
        </b-form-select>
      </b-form-group>
    </div>
    <!-- end: Table Controls    -->

    <b-table
      id="employee-monthly-attendance-table"
      ref="employee-monthly-attendance-table"
      table-class="table-head-custom table-vertical-center mt-3"
      responsive="sm"
      hover
      :fields="fields"
      :items="list"
      :busy="isBusy"
      show-empty
    >
      <!-- begin:header template    -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end:header template    -->

      <!-- begin: cell templates      -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">
          {{ data.index + 1 }}
        </span>
      </template>

      <template v-slot:cell(employee)="data">
        <CommonEmployeeSummary :item="data.item"></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(present)="data">
        <span class="align-self-center">{{ data.item.present_count }}</span>
      </template>

      <template v-slot:cell(absent)="data">
        <span class="align-self-center">{{ data.item.absent_count }}</span>
      </template>

      <template v-slot:cell(leave)="data">
        <span class="align-self-center">{{ data.item.leave_count }}</span>
      </template>

      <template v-slot:cell(away)="data">
        <span class="align-self-center">{{ data.item.away_count }}</span>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-right">
          <!-- begin: Device Users Modal Button         -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showEmployeeAttendance(data)"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
            </span>
          </button>
        </div>
      </template>

      <!-- end: cell templates      -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="list.length">
      <b-pagination
        pills
        size="lg"
        class="pt-5"
        @change="changePage"
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        aria-controls="employee-monthly-attendance-table"
      ></b-pagination>
    </div>

    <EmployeeAttendanceMonthlyModal></EmployeeAttendanceMonthlyModal>
  </div>
</template>

<script>
import i18n from "@/core/plugins/vue-i18n";
import { mapGetters } from "vuex";
import EmployeeAttendanceMonthlyModal from "@/modules/company/components/attendance/monthly/EmployeeAttendanceMonthlyModal";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import dayjs from "dayjs";

export default {
  name: "EmployeeAttendancePageMonthlyTable",
  components: {
    AppLoader,
    CommonEmployeeSummary,
    EmployeeAttendanceMonthlyModal,
  },
  props: ["reload"],
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "employee", label: i18n.t("EMPLOYEE.HEADER") },
        { key: "present", label: "Present" },
        { key: "absent", label: "Absent" },
        { key: "leave", label: "On Leave" },
        { key: "away", label: "Away" },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
      isBusy: false,
      monthSelection: 0,
      yearSelection: 0,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      console.log("Fetching monthly list");
      this.isBusy = true;
      this.$store
        .dispatch("fetchEmployeeMonthlyAttendanceList")
        .finally(() => (this.isBusy = false));
    },

    showEmployeeAttendance(data) {
      console.log("Selected employee, ", data);
      this.$store.dispatch("assignIndividualEmployeeAttendance", data.item);

      const currentDate = new Date();
      this.$store.dispatch(
        "assignIndividualEmployeeAttendanceDate",
        currentDate
      );

      this.$bvModal.show("employee-page-clock-record-modal");
    },

    refresh() {
      this.fetch();
    },

    changePage(value) {
      console.log("Setting val", this.meta.current_page);
      this.$store.commit("setEmployeeMonthlyAttendancePage", value);
      this.fetch();
    },
  },

  watch: {
    reload() {
      console.log("Reload Table!");
      this.fetch();
    },
  },

  computed: {
    ...mapGetters({
      list: "getEmployeeMonthlyAttendanceList",
      meta: "getEmployeeMonthlyAttendanceMeta",
    }),

    years() {
      const now = dayjs();
      let years = [];
      for (let i = 0; i < 5; i++) {
        let sYear = now.add(i, "year");
        years.push({
          value: 0,
          text: sYear.format("YYYY"),
        });
      }

      return years;
    },

    months() {
      require("dayjs/locale/en");
      require("dayjs/locale/ms");
      // const locale = "dayjs/locale/" + this.$i18n.locale;
      // console.log(locale);
      // require("" + locale);
      const localeData = require("dayjs/plugin/localeData");
      dayjs.extend(localeData);
      dayjs.locale(this.$i18n.locale);

      console.log(dayjs.months(), this.$i18n.locale);

      const months = dayjs.months().map((month, key) => {
        return {
          text: month,
          value: key,
        };
      });
      console.log(months);
      return months;
    },
  },
};
</script>

<style scoped></style>
