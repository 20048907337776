<template>
  <div>
    <b-table
      responsive="sm"
      borderless
      table-class="table-head-custom table-vertical-center table-head-bg mt-3"
      hover
      show-empty
      :busy="isBusy"
      :fields="fields"
      :items="getIndividualEmployeeAttendanceList"
    >
      <!-- begin:header template    -->
      <template v-slot:head(date)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end:header template    -->

      <!-- begin: cell templates      -->
      <template v-slot:cell(date)="data">
        <div class="d-flex align-items-center">
          <div class="ml-4">
            <div
              class="
                btn btn-light-primary btn-sm
                font-weight-bold
                btn-upper btn-text
              "
            >
              {{ transformDate(data.item.date) }}
            </div>
            <span class="ml-3 font-weight-bold">{{
              getDay(data.item.date)
            }}</span>
          </div>
        </div>
      </template>

      <template v-slot:cell(time_in)="data">
        <TheTimeEntryArrow
          v-if="data.item.attendance"
          :time="data.item.attendance.time_in"
          type="in"
        ></TheTimeEntryArrow>
      </template>

      <template v-slot:cell(time_out)="data">
        <TheTimeEntryArrow
          v-if="data.item.attendance"
          :time="data.item.attendance.time_out"
          type="out"
        ></TheTimeEntryArrow>
      </template>

      <template v-slot:cell(status)="data">
        <span>{{ data.item.status.name }}</span>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-right">
          <!-- begin: Device Users Modal Button         -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showEmployeeAttendance(data)"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
            </span>
          </button>
          <b-button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showManageModal(data)"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
          </b-button>
        </div>
      </template>
      <!-- end: cell templates      -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>

    <EmployeePageClockRecordModal
      :attendance-id="attendanceId"
    ></EmployeePageClockRecordModal>

    <EmployeeAttendanceManageModal></EmployeeAttendanceManageModal>
  </div>
</template>

<script>
import i18n from "@/core/plugins/vue-i18n";
import { mapGetters } from "vuex";
import EmployeePageClockRecordModal from "@/modules/company/components/employees/EmployeePageClockRecordModal";
import EmployeeAttendanceManageModal from "@/modules/company/components/attendance/manage/EmployeeAttendanceManageModal";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import dayjs from "dayjs";
import TheTimeEntryArrow from "@/modules/company/components/shared/TheTimeEntryArrow";

export default {
  name: "IndividualEmployeeAttendanceMonthlyTable",
  components: {
    TheTimeEntryArrow,
    AppLoader,
    EmployeeAttendanceManageModal,
    EmployeePageClockRecordModal,
  },
  data() {
    return {
      fields: [
        { key: "date", label: "Date" },
        { key: "time_in", label: i18n.t("ATTENDANCE.IN") },
        { key: "time_out", label: i18n.t("ATTENDANCE.OUT") },
        { key: "temperature", label: i18n.t("ATTENDANCE.TEMPERATURE") },
        { key: "status", label: "Status" },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
      attendanceId: null,
      isBusy: true,
    };
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualEmployeeAttendance")
        .finally(() => (this.isBusy = false));
    },

    showEmployeeAttendance(data) {
      this.attendanceId = data.item.attendance ? data.item.attendance.id : null;
      this.$bvModal.show("employee-page-clock-record-modal");
    },

    showManageModal(data) {
      console.log(data);
      const row = data.item;
      const employee = this.employee;
      console.log(employee);
      const emp = {
        id: employee.id,
        name: employee.name,
        code: employee.code,
        email: employee.email,
        att_status: {
          id: row.status.id,
        },
      };
      this.$store.dispatch("assignDailyEmployeeAttendance", {
        data: emp,
        date: dayjs(new Date(data.item.date)).format("YYYY-MM-DD"),
      });
      this.$bvModal.show("emp-attendance-manage-modal");
    },

    transformDate(date) {
      const t = new Date(date);
      // https://day.js.org/docs/en/display/format
      return dayjs(t).format("DD/MM");
    },

    getDay(date) {
      const t = new Date(date);
      // https://day.js.org/docs/en/display/format
      return dayjs(t).format("dddd");
    },

    refresh() {},
  },

  computed: {
    ...mapGetters(["getIndividualEmployeeAttendanceList"]),
    ...mapGetters({
      employee: "getAssignedIndividualEmployeeAttendance",
    }),
  },
};
</script>

<style scoped></style>
