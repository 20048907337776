<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap mb-2 pb-3">
      <div class="flex-row">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("ATTENDANCE.HEADER") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <b-button
          variant="primary"
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
        <span>
          <b-button
            variant="primary"
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="download"
            v-bind:class="{
              'spinner spinner-right spinner-light ml-10': isBusy.report,
            }"
          >
            <span class="svg-icon svg-icon-2x svg-icon-primary">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
          </b-button>
        </span>
      </div>
    </div>
    <b-tabs pills lazy nav-class="nav-light-primary" v-model="tabIndex">
      <b-tab :title="$t('DEPARTMENT.DAILY')">
        <EmployeeAttendancePageTable
          :reload="reload"
        ></EmployeeAttendancePageTable>
      </b-tab>
      <b-tab :title="$t('DEPARTMENT.MONTHLY')">
        <EmployeeAttendancePageMonthlyTable
          :reload="reload"
        ></EmployeeAttendancePageMonthlyTable>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import EmployeeAttendancePageTable from "@/modules/company/components/attendance/EmployeeAttendancePageDailyTable";
import EmployeeAttendancePageMonthlyTable from "@/modules/company/components/attendance/EmployeeAttendancePageMonthlyTable";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { alert } from "sweetalert2";

export default {
  name: "EmployeeAttendancePage",
  components: {
    EmployeeAttendancePageMonthlyTable,
    EmployeeAttendancePageTable,
  },
  data: function () {
    return {
      reload: false,
      tabIndex: 0,
      isBusy: {
        report: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      { title: this.$t("SIDEBAR.EMPLOYEE"), route: "/company/employee/list" },
      {
        title: this.$t("SIDEBAR.ATTENDANCE"),
        route: "/company/employee/attendance",
      },
    ]);
  },
  methods: {
    refresh() {
      this.reload = !this.reload;
    },
    download() {
      console.log("Downloading Attendance Report.");

      switch (this.tabIndex) {
        case 0:
          this.downloadDaily();
          break;
        case 1:
          this.downloadMonthly();
          break;
        default:
          break;
      }
    },

    downloadMonthly() {
      this.isBusy.report = true;
      console.log("Downloading, isBusy?", this.isBusy.report);
      this.$store
        .dispatch("downloadMonthlyReport")
        .then()
        .catch(() => {
          alert({
            title: "Failed",
          });
        })
        .finally(() => (this.isBusy.report = false));
    },

    downloadDaily() {
      this.isBusy.report = true;
      console.log("Downloading, isBusy?", this.isBusy.report);
      this.$store
        .dispatch("downloadDailyReport")
        .then()
        .catch(() => {
          alert({
            title: "Failed",
          });
        })
        .finally(() => (this.isBusy.report = false));
    },
  },
};
</script>

<style scoped></style>
